import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AccountKeyStore from '#/store/AccountKeyStore';
import { Button, ButtonProps, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import InsuranceProduct from '../../lib/api/model/InsuranceProduct';
import AppStateStore from '../../store/AppStateStore';
import TranslationStore from '../../store/TranslationStore';
import ModalDialog from '../ModalDialog';
import { PageTitle } from '../Typograpgy/Title';
import { useBrand } from '@jucy-ui/components';

interface SelectExcessButtonProps extends Omit<ButtonProps, 'onClick'> {
    excessReductionOption: InsuranceProduct;
    onClick?: (excessReductionOption: InsuranceProduct) => void;
}

const SelectExcessButton: React.FC<SelectExcessButtonProps> = ({ excessReductionOption, onClick, ...props }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const handleClick = useCallback(() => onClick?.(excessReductionOption), [onClick, excessReductionOption]);
    const type = useMemo(() => `${excessReductionOption?.ExcessType}`.toLowerCase(), [excessReductionOption?.ExcessType]);
    const variant = useMemo(() => (type === 'basic' ? 'outlined' : 'contained'), [type]);
    const brand = useBrand();

    const label = useMemo(() => {
        if (type === 'basic') {
            return t('agent.continue');
        } else if (type === 'standard') {
            return t('agent.upgrade_to_sf');
        } else {
            return t('agent.upgrade_to_sfp');
        }
    }, [t, type]);

    return (
        <Button {...props} color={brand === 'jucy' ? 'secondary' : 'primary'} variant={variant} onClick={handleClick}>
            {type !== 'basic' ? (
                <>
                    {t('agent.upgrade_to')}
                    <br />
                </>
            ) : null}
            {label}
        </Button>
    );
};

export interface UpsellExcessReductionModalProps {
    excessReductionOptions: InsuranceProduct[];
    setSelectedItem: (code: string, suppressUpsell?: boolean) => void;
}

//Excess Reduction Modal
const UpsellExcessReductionModal: React.FC<UpsellExcessReductionModalProps> = observer(({ excessReductionOptions, setSelectedItem }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const isAgent = AccountKeyStore.isAgent;
    const messageKey = isAgent ? 'agent.message' : 'direct.message';

    const toggle = useCallback(() => {
        AppStateStore.toggleExcessReductionModal();
    }, []);

    const handleClick = useCallback(
        (excessReductionOption: InsuranceProduct) => {
            setSelectedItem(excessReductionOption.ProductCode, true);
            AppStateStore.toggleExcessReductionModal();
        },
        [setSelectedItem]
    );

    return (
        <ModalDialog open={AppStateStore.showExcessReductionModal} onClose={toggle}>
            <DialogTitle component="div">
                <PageTitle className="text-jucy-green">
                    {t('upsell_insurance.heading_1')} <b>{t('upsell_insurance.heading_2')}</b>
                </PageTitle>
            </DialogTitle>
            <DialogContent sx={{ py: 0 }}>
                <p className="smaller">
                    {t(messageKey)} <b>{t('agent.upgrade_to_sf')}</b> or <b>{t('agent.upgrade_to_sfp')}</b>!
                </p>
                {isAgent && <p className="smaller">{t('agent.message_1')}</p>}
            </DialogContent>
            <DialogActions sx={{ alignItems: 'normal', padding: '8px 24px' }}>
                {excessReductionOptions?.map((insurance: InsuranceProduct) => (
                    <SelectExcessButton
                        key={insurance.ProductCode}
                        excessReductionOption={insurance}
                        onClick={handleClick}
                        sx={(t) => ({
                            display: 'flex',
                            flex: '1 1',
                            fontFamily: t.typography.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            lineHeight: 1.2,
                        })}
                    />
                ))}
            </DialogActions>
        </ModalDialog>
    );
});

export default UpsellExcessReductionModal;
