import { Transform, Type } from 'class-transformer';
import { parseISO } from 'date-fns';
import { computed, makeAutoObservable } from 'mobx';
import { FleetType } from '../../../services';
import FleetTypesStore from '../../../store/FleetTypesStore';
import ProductStore from '../../../store/ProductStore';
import SitesStore from '../../../store/SitesStore';
import { isValidDate } from '../../dates';
import type { CatalogItem } from './RentalCatalog';
import { Site } from './Site';

const parseDate = (date: string | undefined) => (date ? parseISO(date) : undefined);
const matchProduct =
    ({ region, productCodes }: { region: string; productCodes: string[] }) =>
    (product: CatalogItem) =>
        product.region === region && productCodes.includes(product.code);

const matchSites = (code: string | string[] | undefined | null): Site[] | undefined => {
    if (!code) {
        return undefined;
    }
    const codes = Array.isArray(code) ? code : [code];
    return SitesStore.sites.filter((site) => codes.includes(site.code)) || undefined;
};

export class Actions {
    dailyDiscountRate?: number;
    dailyDiscountPercent?: number;
    dailyRate?: number;
    bundledProductNames: string[] = [];
    freeDays?: number;
    velocityMultiplier?: number;
    velocityBonus?: number;
}

export class DateBetween {
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    from?: Date;
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    to?: Date;
}

export class Conditions {
    startLocationAt?: string | string[];
    maxHireDays?: number;
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    endDateOnOrBefore?: Date;
    productCodeOneOf?: string[];
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    startDateOnOrAfter?: Date;
    endLocationAt?: string | string[];
    @Type(() => DateBetween)
    endDateBetween?: DateBetween;
    @Type(() => DateBetween)
    startDateBetween?: DateBetween;
    @Type(() => DateBetween)
    blackoutDates?: DateBetween[];
}

export class Deal {
    id = '';
    code = '';
    priority = 0;
    type = '';
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    validFrom: Date = new Date();
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    validTo: Date = new Date();
    region = '';
    tags: string[] = [];
    availableCount = 0;
    @Type(() => Conditions)
    conditions: Conditions = new Conditions();
    @Type(() => Actions)
    actions: Actions = new Actions();
    visible = false;
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    marketingStartDate: Date = new Date();
    @Transform(({ value }) => parseDate(value), { toClassOnly: true })
    marketingEndDate: Date = new Date();
    status = '';

    constructor(props?: Partial<Deal>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    get isRelocation() {
        return this.type === 'vehicle-relocation';
    }

    get pickUpLocations(): Site[] | undefined {
        return matchSites(this.conditions.startLocationAt);
    }

    get dropOffLocations(): Site[] | undefined {
        return matchSites(this.conditions.endLocationAt);
    }

    get earliestStartDate(): Date | undefined {
        return this.conditions.startDateOnOrAfter ? this.conditions.startDateOnOrAfter : this.conditions.startDateBetween && this.conditions.startDateBetween.from;
    }

    get latestEndDate(): Date | undefined {
        if (isValidDate(this.conditions.endDateOnOrBefore)) {
            return this.conditions.endDateOnOrBefore;
        }
        return isValidDate(this.conditions.endDateBetween?.to) ? this.conditions.endDateBetween?.to : undefined;
    }

    get blackoutRanges() {
        if (this.conditions.blackoutDates) {
            return Array.isArray(this.conditions.blackoutDates) ? this.conditions.blackoutDates : [this.conditions.blackoutDates];
        }
        return [];
    }

    get maxHireDays() {
        return this.conditions.maxHireDays;
    }

    @computed get applicableProducts(): CatalogItem[] {
        const productCodes = this.conditions.productCodeOneOf || [];
        const region = this.region;
        return ProductStore.rentalCatalog.filter(matchProduct({ region, productCodes }));
    }

    get fleetTypeSlug() {
        const tags = this.tags || [];
        if (tags.includes('campervan')) {
            return 'campervan';
        }
        if (tags.includes('car')) {
            return 'car';
        }
        return 'campervan';
    }

    get fleetType(): FleetType | undefined {
        return this.fleetTypeSlug ? FleetTypesStore.getFleetTypeBySlug(this.fleetTypeSlug) || undefined : undefined;
    }
}
