import { ExcessReductionFormContextValues } from '#/components/Forms/ExcessReductionForm/ExcessReductionForm';
import { ExcessType, RentalExcessReduction } from '@jucy/rentals-api-client';
import { FleetTypeSlug } from '@jucy/rentals-common';

export const excessFeatures = ['AA breakdown assistance', 'Front/back/side panel damage', 'Windscreen & tyre cover', 'Roof panel damage', 'Single vehicle rollover damage'];

export const campervanExcessFeatures = [
    ...excessFeatures,
    'Overhead and underbody damage',
    'Complimentary: camping chairs (one per person), one camping table',
    'One extra driver $0 fees',
    'One child booster seat (on request)',
    'Pre-purchased gas bottle',
    'Laundry exchange',
    'JUCY GPS',
];
export const motorHomeExcessFeatures = [
    '24/7 Breakdown assistance',
    'Front, back and side panel damage',
    'Windscreen & tyre cover',
    'Roof panel damage',
    'Single vehicle rollover damage',
    'Overhead and underbody damage',
    'Complimentary: camping chairs (one per person), one camping table',
    'One extra driver $0 fees',
    'One child booster seat (on request)',
    'Pre-purchased gas bottle',
    'Laundry exchange',
];
export const getFeatures = ({ fleetTypeSlug, countryCode, fleetCategoryCode }: ExcessReductionFormContextValues) => {
    if (fleetTypeSlug === FleetTypeSlug.campervan) {
        return campervanExcessFeatures.filter((item) => {
            if (countryCode === 'nz' && (fleetCategoryCode === 'CRIB' || fleetCategoryCode === 'CHSR')) {
                return !item.includes('booster');
            }
            return true;
        });
    }
    if (fleetTypeSlug === FleetTypeSlug.motorhome) {
        return motorHomeExcessFeatures;
    }

    return excessFeatures;
};
export const getExcessReductionName = (item: RentalExcessReduction): ExcessType | string => {
    switch (item.excessType) {
        case ExcessType.Full:
            return 'Stress Free Plus';
        case ExcessType.Standard:
            return 'Stress Free';
        case ExcessType.Basic:
            return 'Risk Taker';
        default:
            return item.name || '';
    }
};

export type RecommendationTypes = 'recommended' | 'popular' | 'value';

export const getRecommendations = (item: RentalExcessReduction): RecommendationTypes[] => {
    switch (item.excessType) {
        case ExcessType.Full:
            return ['recommended', 'value'];
        case ExcessType.Standard:
            return ['popular'];
        case ExcessType.Basic:
            return [];
        default:
            return [];
    }
};

export const getExcessReductionCover = (item: RentalExcessReduction): string => {
    switch (item.excessType) {
        case ExcessType.Full:
            return 'Premium Cover';
        case ExcessType.Standard:
            return 'Standard Cover';
        case ExcessType.Basic:
            return 'Basic Cover';
        default:
            return '';
    }
};

export const getExcessReductionFeatures = (item: RentalExcessReduction, data: ExcessReductionFormContextValues): string[] => {
    const insuranceFeatures: Record<FleetTypeSlug, Record<string, string[]>> = {
        car: {
            [ExcessType.Full]: getFeatures(data),
            [ExcessType.Standard]: ['AA breakdown assistance', 'Front/back/side panel damage', 'Windscreen & tyre cover'],
            [ExcessType.Basic]: ['AA breakdown assistance'],
        },
        campervan: {
            [ExcessType.Full]: getFeatures(data),
            [ExcessType.Standard]: ['AA breakdown assistance', 'Front/back/side panel damage', 'Windscreen & tyre cover'],
            [ExcessType.Basic]: ['AA breakdown assistance'],
        },
        motorhome: {
            [ExcessType.Full]: getFeatures(data),
            [ExcessType.Standard]: ['24/7 Breakdown assistance', 'Front, back and side panel damage', 'Windscreen & tyre cover'],
            [ExcessType.Basic]: ['24/7 Breakdown assistance'],
        },
    };
    return insuranceFeatures[data.fleetTypeSlug]?.[item.excessType] ? insuranceFeatures[data.fleetTypeSlug][item.excessType] : [];
};

export const getExcessReductionBonus = (item: RentalExcessReduction, { fleetTypeSlug }: ExcessReductionFormContextValues): string | undefined => {
    if (FleetTypeSlug.campervan === fleetTypeSlug) {
        switch (item.excessType) {
            case ExcessType.Full:
                return 'Bonus: get complimentary table, chairs, additional driver, and JUCY GPS';
            case ExcessType.Standard:
                return '';
            case ExcessType.Basic:
                return '';
        }
    } else if (FleetTypeSlug.motorhome === fleetTypeSlug) {
        switch (item.excessType) {
            case ExcessType.Full:
                return 'Bonus: get complimentary table, chairs and additional driver';
            case ExcessType.Standard:
                return '';
            case ExcessType.Basic:
                return '';
        }
    }
    return undefined;
};

export const getExcessReductionAdminFee = (item: RentalExcessReduction): string | number | undefined => {
    switch (item.excessType) {
        case ExcessType.Full:
            return 'FREE';
        case ExcessType.Standard:
            return 'FREE';
        case ExcessType.Basic:
            return 75;
        default:
            return undefined;
    }
};

export const getExcessReductionSortOrder = (item: RentalExcessReduction): number => {
    switch (item.excessType) {
        case ExcessType.Full:
            return 0;
        case ExcessType.Standard:
            return 1;
        case ExcessType.Basic:
            return 2;
        default:
            return 3;
    }
};

export const getExcessReductionPriceDetails = (
    item: RentalExcessReduction
): {
    id: string;
    label: string;
    value: string | number;
    currency?: string;
}[] => [
    {
        id: 'excess',
        label: `Excess liability${item.name?.toLowerCase().includes('star') || item.name?.toLowerCase().includes('motorhome') ? '*' : ''}`,
        value: item.excess.value || 0,
        currency: item.excess.currencyCode,
    },
    {
        id: 'bond',
        label: `Bond required${item.bond.value !== 0 ? '' : item.name?.toLowerCase().includes('motorhome') ? '**' : '*'}`,
        value: item.bond.value || 0,
        currency: item.bond.currencyCode,
    },
    {
        id: 'admin',
        label: 'Claim/AdminFee',
        value: getExcessReductionAdminFee(item) || 0,
        currency: item.excess.currencyCode,
    },
];
