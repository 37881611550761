import { isAbortError } from '#/lib/isAbortError';
import { GetFleetTypeCategoriesResponseFromJSON } from '@jucy/rentals-api-client';
import { AvailabilitySearchResponse, TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { FleetTypeCode } from '@jucy/rentals-api-client/rentals-api-v3';
import { format, formatISO9075, isBefore, isWithinInterval } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { action, isObservableArray, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { SuperAgentRequest } from 'superagent';
import { Writable } from 'type-fest';
import ErrorReporter from '../lib/ErrorReporter';
import JucyAPI from '../lib/JucyAPI.js';
import { TripInfo } from '../lib/TripInfo';
import { Deal } from '../lib/api/model/Deal';
import FleetCategoryAvailability from '../lib/api/model/FleetCategoryAvailability';
import Quote from '../lib/api/model/Quote';
import type { CatalogItem } from '../lib/api/model/RentalCatalog';
import { Site } from '../lib/api/model/Site';
import { setTime } from '../lib/dates';
import getJucyRentalsApiClient from '../lib/getJucyRentalsApiClient';
import { quoteToValues } from '../lib/quoteToValues';
import { resolveError } from '../lib/resolveError';
import { FleetType } from '../services';
import AccountKeyStore from './AccountKeyStore';
import { AvailabilitySearchResult, alternateDatesStore } from './AlternateDatesStore';
import { defaultDriverAge, defaultDropOffDate, defaultPickUpDate, defaultSite } from './DefaultsStore.js';
import FleetTypesStore from './FleetTypesStore';
import ProductStore from './ProductStore';
import rentalTripSearchStore from './RentalTripSearchStore';
import { reverseTripStore } from './ReverseTripStore';
import tripStore from './TripStore';

class AvailabilityStore {
    fleetCategories: FleetCategoryAvailability[] = [];
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    error?: Error;

    fleetType?: FleetType;
    pickUpLocation?: Site = defaultSite || undefined;
    pickUpDate?: Date = defaultPickUpDate || undefined;
    pickUpTime?: Date = defaultPickUpDate || undefined;
    dropOffLocation?: Site = defaultSite || undefined;
    dropOffDate?: Date = defaultDropOffDate || undefined;
    dropOffTime?: Date = defaultDropOffDate || undefined;
    driverAge: string = defaultDriverAge;
    promoCode = '';
    selectedFleetCategory?: FleetCategoryAvailability = undefined;
    selectedDeal?: Deal = undefined;
    lastRequest?: SuperAgentRequest;
    abortController?: AbortController;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'availability' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    get availableCount() {
        return this.availableFleetCategories.length;
    }

    get freeSellCount() {
        return this.availableFleetCategories.filter((f) => f.Availability === 'FreeSell').length;
    }

    get onRequestCount() {
        return this.availableFleetCategories.filter((f) => f.Availability === 'OnRequest').length;
    }

    get categoryFilter() {
        if (!this.selectedDeal || !this.selectedDeal.applicableProducts?.length) {
            return () => true;
        }
        const productCodes = this.selectedDeal.applicableProducts.map((p) => p.code);
        return (category: FleetCategoryAvailability) => productCodes.includes(category.CategoryCode);
    }

    get availableFleetCategories(): FleetCategoryAvailability[] {
        const categories = this.fleetCategories.filter(this.isFleetCategoryAvailable).filter(this.categoryFilter);
        return sortBy(categories, ['Availability', 'Total.Value']);
    }

    get asQueryString() {
        return new URLSearchParams({
            pickUpLocation: this.pickUpLocation && this.pickUpLocation.SiteCode,
            pickUpDate: this.pickUpDate && format(this.pickUpDate, 'yyyy-MM-dd'),
            pickUpTime: this.pickUpTime && format(this.pickUpTime, 'HHmm'),
            dropOffLocation: this.dropOffLocation && this.dropOffLocation.SiteCode,
            dropOffDate: this.dropOffDate && format(this.dropOffDate, 'yyyy-MM-dd'),
            dropOffTime: this.dropOffTime && format(this.dropOffTime, 'HHmm'),
        } as Record<string, string>).toString();
    }

    get hasTripInfo() {
        return Boolean(this.pickUpLocation && this.pickUpDate && this.pickUpTime && this.dropOffLocation && this.dropOffDate && this.dropOffTime);
    }

    get campaignCode() {
        return this.promoCode || this.selectedDeal?.code || '';
    }

    setValues = (values: Partial<AvailabilityStore>) => {
        Object.entries(values).forEach(([name, value]) => {
            const self = this as Writable<AvailabilityStore>;
            const prop = name as keyof Writable<AvailabilityStore>;
            const currentValue = self[prop];
            runInAction(() => {
                if (currentValue !== value) {
                    self[prop] = value as never;
                }
            });
        });
    };

    reset() {
        runInAction(() => {
            this.fleetCategories = [];
            this.selectedFleetCategory = undefined;
            this.state = 'pending';
            this.message = '';
            this.pickUpLocation = undefined;
            this.pickUpDate = undefined;
            this.pickUpTime = undefined;
            this.dropOffLocation = undefined;
            this.dropOffDate = undefined;
            this.dropOffTime = undefined;
            this.driverAge = '';
        });
    }

    isFleetCategoryAvailable = (fleetCategory: FleetCategoryAvailability) => {
        if (fleetCategory.constraints) {
            const { bookingMin, bookingMax, hireMinDate, hireMaxDate, allowedPickUpLocationCodes, allowedDropOffLocationCodes } = fleetCategory.constraints;
            const tripInfo = this.asTripInfo();
            const now = new Date();
            const pickUpDateTime = setTime(this.pickUpDate as Date, this.pickUpTime as Date);
            const dropOffDateTime = setTime(this.dropOffDate as Date, this.dropOffTime as Date);

            if (bookingMin && bookingMax && !isWithinInterval(now, { start: bookingMin, end: bookingMax })) {
                return false;
            }
            if (allowedPickUpLocationCodes && tripInfo?.pickUpLocation?.code && !allowedPickUpLocationCodes.includes(tripInfo?.pickUpLocation?.code)) {
                return false;
            }
            if (allowedDropOffLocationCodes && tripInfo?.dropOffLocation?.code && !allowedDropOffLocationCodes.includes(tripInfo?.dropOffLocation?.code)) {
                return false;
            }
            if (
                hireMinDate &&
                hireMaxDate &&
                !isWithinInterval(pickUpDateTime, {
                    start: hireMinDate,
                    end: hireMaxDate,
                })
            ) {
                return false;
            }
            if (hireMaxDate && !isBefore(dropOffDateTime, hireMaxDate)) {
                return false;
            }
        }
        return true;
    };

    getFleetCategory(code: string) {
        return this.availableFleetCategories.find((f) => f.CategoryCode === code);
    }

    getAvailability() {
        this.state = 'loading';
        this.message = '';
        if (this.lastRequest) {
            this.lastRequest.abort();
        }
        const request = {
            typeId: this.fleetType?.id,
            pickUpLocation: this.pickUpLocation?.SiteCode,
            dropOffLocation: this.dropOffLocation?.SiteCode,
            pickUpDateTime: format(setTime(this.pickUpDate as Date, this.pickUpTime as Date), 'yyyy-MM-dd HH:mm'),
            dropOffDateTime: format(setTime(this.dropOffDate as Date, this.dropOffTime as Date), 'yyyy-MM-dd HH:mm'),
            driverAge: this.driverAge,
            campaignCode: this.campaignCode,
        };

        this.updateReverseTripStore();
        if (!this.campaignCode && !rentalTripSearchStore.selectedDeal) {
            this.updateAlternateDatesStore();
        }
        this.lastRequest = JucyAPI.getAvailability(request);
        return this.lastRequest
            .then((res) => res.body)
            .then(
                action((response) => {
                    if (response.ResponseType !== JucyAPI.responseTypes.success) {
                        this.message = response.Message;
                        this.state = 'error';
                    } else {
                        reverseTripStore.forwardResults = GetFleetTypeCategoriesResponseFromJSON(response)?.data?.[1]?.categories || [];
                        this.fleetCategories =
                            response.Data[1] &&
                            response.Data[1].Categories.map((apiFleetCategory: Partial<FleetCategoryAvailability>) =>
                                FleetCategoryAvailability.fromApi({
                                    apiFleetCategory,
                                    fleetType: this.fleetType as FleetType,
                                    catalogData: ProductStore.getProductByCode(apiFleetCategory.CategoryCode, this.pickUpLocation?.CountryCode) as CatalogItem,
                                })
                            );
                        this.state = 'done';
                    }
                    return response;
                })
            )
            .catch((e) =>
                resolveError(e).then(({ error, message }) => {
                    if (isAbortError(error)) {
                        return;
                    }
                    runInAction(() => {
                        this.state = 'error';
                        this.error = error;
                        this.message = message;
                    });
                    return error;
                })
            );
    }

    async getAvailabilityV3(reservationReference?: string): Promise<AvailabilitySearchResponse | undefined> {
        try {
            this.state = 'loading';
            this.message = '';
            if (this.lastRequest) {
                this.lastRequest.abort();
            }

            if (this.abortController?.abort) {
                this.abortController?.abort();
            }
            this.updateAlternateDatesStore();
            this.updateReverseTripStore();
            this.abortController = new AbortController();

            const response = await getJucyRentalsApiClient({ user: AccountKeyStore.user }).availabilitySearch(
                {
                    pickUpLocation: this.pickUpLocation?.SiteCode || '',
                    dropOffLocation: this.dropOffLocation?.SiteCode || '',
                    pickUpDate: formatISO9075(setTime(this.pickUpDate as Date, this.pickUpTime as Date)),
                    dropOffDate: formatISO9075(setTime(this.dropOffDate as Date, this.dropOffTime as Date)),
                    fleetTypeCode: this.fleetType?.slug as FleetTypeCode,
                    driverAge: this.driverAge ? Number(this.driverAge) : undefined,
                    campaignCode: this.campaignCode,
                    reservationReference,
                },
                { signal: this.abortController.signal }
            );
            this.fleetCategories = response.fleetCategories.map((fleetCategory) =>
                FleetCategoryAvailability.formV3Api({
                    apiFleetCategory: fleetCategory,
                    fleetType: FleetTypesStore.getFleetTypeBySlug(fleetCategory?.fleetTypeCode) as FleetType,
                    catalogData: ProductStore.getProductByCode(fleetCategory.categoryCode, this.pickUpLocation?.CountryCode),
                })
            );
            this.state = 'done';
            return response;
        } catch (e) {
            resolveError(e).then(({ error, message }) => {
                if (isAbortError(error)) {
                    return error;
                }
                runInAction(() => {
                    this.state = 'error';
                    this.error = error;
                    this.message = message;
                });
                return error;
            });
        }
        return undefined;
    }

    setValuesFromQuote = (quote: Quote) => {
        if (quote) {
            this.setValues(quoteToValues(quote));
        }
    };

    updateResults(results: AvailabilitySearchResult) {
        runInAction(() => {
            if (tripStore.tripInfo) {
                tripStore.tripInfo.pickUpDate = formatISO9075(results.pickUpDate);
                tripStore.tripInfo.dropOffDate = formatISO9075(results.dropOffDate);
            }
            if (tripStore.summary) {
                tripStore.summary.pickUpDate = results.pickUpDate;
                tripStore.summary.dropOffDate = results.dropOffDate;
            }
            rentalTripSearchStore.pickUpDate = results.pickUpDate;
            rentalTripSearchStore.dropOffDate = results.dropOffDate;
            availabilityStore.pickUpDate = results.pickUpDate;
            availabilityStore.dropOffDate = results.dropOffDate;
            alternateDatesStore.trip.pickUpDate = results.pickUpDate;
            alternateDatesStore.trip.dropOffDate = results.dropOffDate;
            rentalTripSearchStore.formContext?.setFieldValue('pickUpDate', results.pickUpDate);
            rentalTripSearchStore.formContext?.setFieldValue('dropOffDate', results.dropOffDate);
            availabilityStore.fleetCategories = results.fleetCategories.map((fleetCategory) =>
                FleetCategoryAvailability.formV3Api({
                    apiFleetCategory: fleetCategory,
                    fleetType: FleetTypesStore.getFleetTypeBySlug(fleetCategory?.fleetTypeCode) as FleetType,
                    catalogData: ProductStore.getProductByCode(fleetCategory?.categoryCode, this.pickUpLocation?.CountryCode) as CatalogItem,
                })
            );
        });
    }

    asTrip() {
        return {
            apiKey: AccountKeyStore.accountKey,
            pickUpLocation: this.pickUpLocation?.SiteCode || '',
            dropOffLocation: this.dropOffLocation?.SiteCode || '',
            pickUpDate: setTime(this.pickUpDate as Date, this.pickUpTime as Date),
            dropOffDate: setTime(this.dropOffDate as Date, this.dropOffTime as Date),
            fleetType: this.fleetType?.slug || '',
            driverAge: this.driverAge ? Number(this.driverAge) : undefined,
            campaignCode: this.campaignCode,
        };
    }

    updateReverseTripStore() {
        reverseTripStore.setTrip(this.asTrip());
    }

    updateAlternateDatesStore() {
        alternateDatesStore.setTrip(this.asTrip());
    }

    setSelectedFleetCategoryByCode(fleetCategoryCode?: string) {
        this.selectedFleetCategory = availabilityStore.availableFleetCategories.find((f) => f.CategoryCode === fleetCategoryCode);
    }

    setSelectedFleetCategory(fleetCategory: FleetCategoryAvailability) {
        this.selectedFleetCategory = fleetCategory;
    }

    setFleetCategories(fleetCategories: FleetCategoryAvailability[]) {
        if (isObservableArray(this.fleetCategories)) {
            this.fleetCategories.replace(fleetCategories);
        } else {
            this.fleetCategories = fleetCategories;
        }
    }

    shouldAutoSelectRelocation() {
        if (!this.selectedDeal) {
            return false;
        }
        if (!['deal', 'vehicle-relocation'].includes(this.selectedDeal.type)) {
            return false;
        }
        if (this.availableFleetCategories.length !== 1) {
            return false;
        }
        return true;
    }

    asTripInfo(): TripInfo {
        const { pickUpLocation, dropOffLocation, pickUpDate, pickUpTime, dropOffDate, dropOffTime, driverAge } = toJS(this);

        return {
            pickUpLocation,
            dropOffLocation,
            pickUpDate,
            pickUpTime,
            dropOffDate,
            dropOffTime,
            driverAge,
            fleetCategory: this.selectedFleetCategory,
            campaignCode: this.campaignCode || (this.selectedFleetCategory && this.selectedFleetCategory.campaignCode),
        };
    }

    asTripInfoRequest(): Partial<TripInfoRequest> {
        const { pickUpLocation, dropOffLocation, pickUpDate, pickUpTime, dropOffDate, dropOffTime, driverAge } = toJS(this);
        return {
            pickUpLocation: pickUpLocation?.SiteCode,
            dropOffLocation: dropOffLocation?.SiteCode,
            pickUpDate: format(setTime(pickUpDate as Date, pickUpTime as Date), 'yyyy-MM-dd HH:mm'),
            dropOffDate: format(setTime(dropOffDate as Date, dropOffTime as Date), 'yyyy-MM-dd HH:mm'),
            driverAge: Number(driverAge),
            fleetCategory: this.selectedFleetCategory?.CategoryCode,
            campaignCode: this.campaignCode || (this.selectedFleetCategory && this.selectedFleetCategory.campaignCode),
        };
    }
}

const availabilityStore = new AvailabilityStore();
export default availabilityStore;
