import React, { MouseEvent, useCallback } from 'react';
import { useExcessReductionFormContext } from '#/components/Forms/ExcessReductionForm/ExcessReductionForm';
import { ExcessType, RentalExcessReduction } from '@jucy/rentals-api-client';
import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Actions, Banner, BonusItems, FeaturesList, Price, PriceDetailsList, Title } from './components';
import { useBrand } from '@jucy-ui/components';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('xl')]: {
                marginTop: theme.spacing(2),
            },
            marginBottom: theme.spacing(4),
            ['&.MuiGrid-item']: {
                paddingTop: '32px',
            },
        },
        card: {
            cursor: 'pointer',
            height: '100%',
            position: 'relative',
            overflow: 'visible',
            display: 'flex',
            flexDirection: 'column',
            transition: theme.transitions.create('border-color, background'),
            borderColor: theme.palette.action.focus,
            background: theme.palette.common.white,
            '&.active, &:hover': {
                background: theme.palette.action.hover,
                borderColor: theme.palette.secondary.dark,
            },
            '&.active, &.isSelecting': {
                cursor: 'default',
            },
        },
        actions: {
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        list: {
            fontSize: 12,
        },
        recommendedCard: {},
        recommendedBanner: {
            textTransform: 'capitalize',
            border: `1px solid ${theme.palette.primary.dark}`,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: (theme.typography.caption.fontWeight as number) * 2,
            position: 'absolute',
            top: -parseInt(theme.spacing(2)),
            left: theme.spacing(2),
            borderRadius: Number(theme.shape.borderRadius) * 5,
            paddingTop: theme.spacing(0.5),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            '&.value': {
                background: theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.secondary.main,
            },
            '&.popular': {
                background: theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.secondary.main,
            },
        },
        title: {
            marginBottom: theme.spacing(1),
        },
        price: {
            marginTop: theme.spacing(2),
        },
        bonuses: {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightBold as unknown as undefined,
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                minHeight: 36,
            },
        },
        priceDetails: {
            marginTop: 'auto',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        standard: {
            marginBottom: theme.spacing(2),

            ['&.MuiGrid-item']: {
                paddingTop: 0,
                paddingBottom: 0,
                marginBottom: 0,
                '& .features-list': {
                    marginTop: 10,
                },
                '& .price-details': {
                    marginTop: 4,
                },
                '& .MuiCardActions-root': {},
                '& .select-item-btn': {},
                '& .actions': {
                    marginTop: 0,
                    marginBottom: 'auto',
                },
                '& .card-excess-reduction': {
                    borderWidth: '4px',
                    marginBottom: theme.spacing(4),
                    paddingTop: theme.spacing(2),
                },
            },
        },
    })
);

interface ExcessReductionProps {
    item: Required<RentalExcessReduction>;
}
const ExcessReductionHorizontalCard: React.FC<ExcessReductionProps> = ({ item }) => {
    const { isSelecting, readonly, activeCode, onSelect } = useExcessReductionFormContext();
    const isActive = activeCode === item.productCode;
    const classes = useStyles();
    const brand = useBrand();
    const handleClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            if (!readonly) {
                onSelect(item.productCode);
            }
        },
        [item.productCode, onSelect, readonly]
    );
    const className = item.excessType.toLowerCase() as keyof typeof classes;
    return (
        <Grid item md={4} xs={12} zeroMinWidth className={clsx(classes.root, classes[className])} data-cy={`card-excess-reduction-${item.productCode}`} onClick={handleClick}>
            <Card className={clsx(classes.card, 'card-excess-reduction', { active: isActive, selecting: isSelecting })} variant="outlined">
                <Banner item={item} variant="popular" className={classes.recommendedBanner} />
                <CardContent className={classes.content}>
                    <Title className={classes.title} item={item} />
                    <Typography className={clsx('features-list', classes.list)} component="div">
                        Includes:
                        <FeaturesList item={item} />
                    </Typography>
                    <PriceDetailsList className={clsx('list-unstyled text-start mb-2 fw-bold smaller price-details', classes.priceDetails)} item={item} />
                    <BonusItems className={classes.bonuses} item={item} />
                    <Price className={classes.price} item={item} />
                </CardContent>
                <CardActions className={clsx('actions', classes.actions)}>
                    <Actions
                        buttonProps={{
                            color: 'secondary',
                            variant: 'contained',
                            sx: (theme) => ({
                                backgroundColor:
                                    brand === 'star'
                                        ? item.excessType === ExcessType.Basic
                                            ? theme.palette.primary.light
                                            : theme.palette.primary.main
                                        : item.excessType === ExcessType.Basic
                                          ? theme.palette.secondary.light
                                          : theme.palette.secondary.main,
                                borderWidth: 1,
                                opacity: isActive || item.excessType === ExcessType.Basic ? undefined : 0.75,
                            }),
                        }}
                        item={item}
                    />
                </CardActions>
            </Card>
        </Grid>
    );
};
export default ExcessReductionHorizontalCard;
