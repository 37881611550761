import { VelocityFleetCategoryTeaserPanel } from '#/components/Velocity/VelocityFleetCategoryTeaserPanel';
import { Card, CardContent, useTheme, Unstable_Grid2 as Grid } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent, useCallback } from 'react';
import { useFeatureIsOn } from '../../../../hooks/useFeatureIsOn';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import AlternateDatesSlider from '../AlternateDatesSlider';
import DealBanner from './components/DealBanner';
import FleetCategoryActions from './components/FleetCategoryActions';
import FleetCategoryDescription from './components/FleetCategoryDescription';
import FleetCategoryDetails from './components/FleetCategoryDetails';
import FleetCategoryDetailsDialog from './components/FleetCategoryDetailsDialog';
import { TeaserProvider, useTeaserContext } from './TeaserContext';

export interface TeaserProps {
    fleetCategory: FleetCategoryAvailability;
    onClick?: (fleetCategory: FleetCategoryAvailability) => void;
    isActive?: boolean;
    isHighlighted?: boolean;
    isLoading?: boolean;
    currencyCode: string;
    action?: 'create' | 'edit';
    className?: string;

    components?: { Description?: React.ReactNode; Details?: React.ReactNode; Actions?: React.ReactNode };
}

const Teaser: React.FC<TeaserProps> = observer(({ components, fleetCategory, onClick, isActive, isHighlighted, className, isLoading, currencyCode, action }) => {
    const alternateDatesEnabled = useFeatureIsOn('alternate-dates');
    const handleClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            if (!fleetCategory.isStopSell && onClick) {
                onClick(fleetCategory);
            }
        },
        [fleetCategory, onClick]
    );
    const isNova = fleetCategory.product.code.toLowerCase().includes('nov');

    return (
        <TeaserProvider
            value={{
                fleetCategory,
                onClick,
                isActive,
                isHighlighted,
                onSelect: handleClick,
                isLoading,
                currencyCode,
                action,
                isNova,
            }}
        >
            {alternateDatesEnabled && <AlternateDatesSlider />}
            <DealBanner />
            <TeaserCard className={className} action={action} components={components} />
            <FleetCategoryDetailsDialog />
        </TeaserProvider>
    );
});

type TeaserCardProps = Pick<TeaserProps, 'action' | 'components' | 'className'>;
const TeaserCard: React.FC<TeaserCardProps> = observer(({ action, className, components }) => {
    const theme = useTheme();
    const { deal, fleetCategory, isHighlighted, isActive } = useTeaserContext();

    return (
        <Card
            data-cy="card-car"
            data-fleet-category-code={fleetCategory.product.code}
            elevation={isHighlighted ? 1 : 0}
            className={clsx(className, 'fleet-category-teaser')}
            sx={{
                marginBottom: 2,
                borderColor: isActive ? theme.palette.secondary.main : theme.palette.grey[200],
                borderWidth: 1,
                borderStyle: 'solid',
                transition: 'border-color .35s ease,opacity .35s ease',
                '&:hover': {
                    borderColor: theme.palette.secondary.main,
                },
                ...(deal?.enabled && (!fleetCategory.isOnRequest || action !== 'edit')
                    ? {
                          borderTopWidth: 0,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                      }
                    : undefined),
            }}
        >
            <CardContent sx={{ '&:last-child': { paddingBottom: 2 } }}>
                <Grid container spacing={3}>
                    <Grid xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        {components?.Description ? components.Description : <FleetCategoryDescription />}
                    </Grid>
                    <Grid container xs={12} sm={8}>
                        <Grid xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {components?.Details ? components.Details : <FleetCategoryDetails />}
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            {components?.Actions ? components.Actions : <FleetCategoryActions />}
                        </Grid>
                    </Grid>
                    <VelocityFleetCategoryTeaserPanel fleetCategory={fleetCategory} deal={deal} />
                </Grid>
            </CardContent>
        </Card>
    );
});

Teaser.displayName = 'Teaser';
export default Teaser;
