import { useQuery } from '@tanstack/react-query';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { Deal } from '#/lib/api/model/Deal';
import dealStore from '#/store/DealStore';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkIcon from '@mui/icons-material/Work';
import FleetCategoryAvailability from '../../../../lib/api/model/FleetCategoryAvailability';
import CurrencyStore from '../../../../store/CurrencyStore';
import { reverseTripStore } from '../../../../store/ReverseTripStore';
import { formatCurrency, Price } from '../../../CurrencyFormatter/CurrencyFormatter';
import { FleetTypeSlug } from '@jucy/rentals-common';

type DetailIcons = {
    title: string;
    icon: React.ReactNode;
    count: number;
};

type DealSummary = {
    type?: string;
    enabled: boolean;
    title: string;
    hasCheaperReverseTrip: boolean;
    reverseTotal: Price | null;
} & Partial<Deal>;

export interface TeaserProviderProps {
    fleetCategory: FleetCategoryAvailability;
    onClick?: (fleetCategory: FleetCategoryAvailability) => void;
    isActive?: boolean;
    isHighlighted?: boolean;
    onSelect: (e: MouseEvent) => void;
    isLoading?: boolean;
    currencyCode: string;
    action?: 'create' | 'edit';
    deal?: DealSummary;
    isNova?: boolean;
}

export interface TeaserContextValues extends TeaserProviderProps {
    toggleDetailsModal: (e: MouseEvent) => void;
    detailsModalOpen: boolean;
    detailIcons: DetailIcons[];
    fleetType: FleetTypeSlug;
}

const getDeal = ({ fleetCategory, action, deal }: { fleetCategory: FleetCategoryAvailability; action?: 'create' | 'edit'; deal?: Deal }): DealSummary => {
    const selectedCurrencyCode = CurrencyStore?.selectedCurrencyCode || fleetCategory?.currencyCode || '';
    const reverseDiff = reverseTripStore.reversePriceDiff[fleetCategory.CategoryCode];
    const dealText = `${fleetCategory.dealText || fleetCategory.campaignCode || ''}`.trim();
    const hasDealText = Boolean(dealText);
    const hasDiscountedDailyRate = Boolean(fleetCategory.dailyRate.value < fleetCategory.originalDailyRate.value);
    const hasCheaperReverseTrip = Boolean(reverseDiff && reverseDiff < 0);
    const text: string[] = [];

    const isFreeDaysDeal = typeof deal?.actions?.freeDays === 'number' && deal?.actions?.freeDays > 0;
    const isRelocation = deal?.type === 'vehicle-relocation';

    if (dealText) {
        text.push(dealText);
    }
    if (!isRelocation && !isFreeDaysDeal && fleetCategory.DiscountPercentageApplied) {
        text.push(
            `${fleetCategory.DiscountPercentageApplied * 100}% discount applied - Save ${selectedCurrencyCode}${formatCurrency(
                fleetCategory.TotalDiscountAmount,
                fleetCategory?.currencyCode
            )}`
        );
    }
    if (typeof deal?.actions?.freeDays === 'number' && deal?.actions?.freeDays > 0) {
        text.push(`${deal.actions.freeDays} free day${deal.actions.freeDays === 1 ? '' : 's'} discount applied`);
    } else if (!fleetCategory.DiscountPercentageApplied && fleetCategory.TotalDiscountAmount.value) {
        text.push(`${selectedCurrencyCode}${formatCurrency(fleetCategory.TotalDiscountAmount, fleetCategory?.currencyCode)} discount applied`);
    }
    const hasDeal = Boolean(
        deal &&
            fleetCategory.campaignId === deal?.id &&
            !(fleetCategory.isStopSell || !(hasDealText || hasDiscountedDailyRate || hasCheaperReverseTrip) || (fleetCategory.isOnRequest && action === 'edit'))
    );

    return {
        actions: deal?.actions,
        type: deal?.type,
        id: deal?.id,
        code: deal?.code,
        enabled: hasDeal,
        title: text.join(' - '),
        hasCheaperReverseTrip,
        reverseTotal: hasCheaperReverseTrip
            ? {
                  value: reverseDiff * -1,
                  currencyCode: fleetCategory?.currencyCode,
              }
            : null,
    };
};

const getDetailIcons = (fleetCategory: FleetCategoryAvailability): DetailIcons[] => [
    { title: 'Seats', icon: <GroupsIcon />, count: fleetCategory.product.seatCount },
    { title: 'Sleeps', icon: <AirlineSeatIndividualSuiteIcon />, count: fleetCategory.product.sleepCount },
    { title: 'Luggage large', icon: <BusinessCenterIcon />, count: fleetCategory.product.luggageLarge },
    { title: 'Luggage small', icon: <WorkIcon />, count: fleetCategory.product.luggageSmall },
];

export const TeaserContext = React.createContext<TeaserContextValues | null>(null);

export const TeaserProvider: React.FC<{ value: TeaserProviderProps; children?: React.ReactNode }> = ({ children, value }) => {
    const [modal, setModal] = useState(false);
    const { data: deal } = useQuery({
        queryKey: ['deal', value.fleetCategory.CampaignId],
        queryFn: () => dealStore.fetchDeal(value.fleetCategory.CampaignId),
        enabled: dealStore.isValidDealId(value.fleetCategory.CampaignId),
    });
    const toggleDetailsModal = useCallback(
        (e: MouseEvent) => {
            window.dataLayer?.push({
                event: 'click',
                eventAction: 'fleet-category-more-info',
                eventCategory: 'statistics',
                eventLabel: value.fleetCategory.Name,
                eventValue: undefined,
            });
            if (value.fleetCategory.product.extended) {
                e.preventDefault();
                setModal(!modal);
            }
        },
        [value.fleetCategory.Name, value.fleetCategory.product.extended, modal]
    );
    const values: TeaserContextValues = useMemo(
        () => ({
            ...value,
            fleetType: value.fleetCategory?.fleetType?.slug as FleetTypeSlug,
            toggleDetailsModal,
            detailsModalOpen: modal,
            detailIcons: getDetailIcons(value.fleetCategory),
            deal: getDeal({ fleetCategory: value.fleetCategory, action: value.action, deal }),
        }),
        [value, toggleDetailsModal, modal, deal]
    );

    return <TeaserContext.Provider value={values}>{children}</TeaserContext.Provider>;
};

export const useTeaserContext = (): TeaserContextValues => {
    const values = React.useContext(TeaserContext);
    if (!values) {
        throw new Error('useTeaserContext must be used within a TeaserProvider.');
    }
    return values;
};
