import { runInAction } from 'mobx';
import JucyAPI from '../lib/JucyAPI.js';
import { Deal } from '../lib/api/model/Deal';
import { queryClient } from '#/layouts/ClientOnlyObeLayout/lib/init';

class DealStore {
    deals: Record<string, Deal> = {};
    state = 'pending';

    async fetchDeal(dealId: string) {
        if (!this.isValidDealId(dealId)) {
            return undefined;
        }
        if (this.deals[dealId]) {
            return this.deals[dealId];
        }

        const deal = await queryClient.fetchQuery({
            queryKey: ['deal', dealId],
            queryFn: async () => (await JucyAPI.getDeal(dealId)) as unknown as Deal,
        });

        if (deal) {
            return runInAction(() => {
                this.deals[dealId] = new Deal(deal);
                this.state = 'done';
                return this.deals[dealId];
            });
        }
    }

    isValidDealId(dealId?: string) {
        return Boolean(dealId && dealId !== '00000000-0000-0000-0000-000000000000');
    }
}

export default new DealStore();
