import { autorun, makeAutoObservable } from 'mobx';
import getJucyRentalsApiClient from '#/lib/getJucyRentalsApiClient';
import { RewardPointsConfig } from '@jucy/rentals-api-client/rentals-api-v3/models/RewardPointsConfig';
import accountKeyStore from '#/store/AccountKeyStore';
import rentalTripSearchStore from '#/store/RentalTripSearchStore';
import { queryClient } from '#/layouts/ClientOnlyObeLayout/lib/init';
import tripStore from '#/store/TripStore';
import reservationStore from '#/store/ReservationStore';
import availabilityStore from '#/store/AvailabilityStore';
import dealStore from '#/store/DealStore';

class RewardPointsStore {
    config?: RewardPointsConfig[] = [];

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            const dealIdByTripSearchStore = rentalTripSearchStore.selectedDeal?.id;
            const dealIdByTripStore = tripStore.booking?.fleetCategory?.campaignId;
            const dealIdByReservationStore = reservationStore.activeQuote?.FleetCategory?.campaignId;
            const dealIdByAvailabilityStore =
                availabilityStore.selectedFleetCategory?.campaignId || availabilityStore.availableFleetCategories.find((fc) => dealStore.isValidDealId(fc.campaignId))?.campaignId;

            const fleetTypeByTriStore = tripStore.booking?.fleetCategory?.fleetTypeCode;
            const fleetTypeByTripSearchStore = rentalTripSearchStore.fleetTypeSlug;
            const fleetTypeByReservationStore = reservationStore.activeQuote?.FleetCategory?.fleetType?.slug;

            const fleetTypeSlug = fleetTypeByTripSearchStore || fleetTypeByTriStore || fleetTypeByReservationStore;
            const dealId = [dealIdByTripSearchStore, dealIdByTripStore, dealIdByReservationStore, dealIdByAvailabilityStore].find(dealStore.isValidDealId);

            this.fetchStatus({ fleetTypeSlug, dealId });
        });
    }

    init() {
        this.fetchStatus();
    }

    fetchStatus({ fleetTypeSlug, dealId }: { fleetTypeSlug?: string; dealId?: string } = {}) {
        queryClient
            .fetchQuery({
                queryKey: ['rewardPoints', 'config', fleetTypeSlug, dealId],
                queryFn: async () => {
                    try {
                        return await getJucyRentalsApiClient({ accountKey: accountKeyStore.accountKey }).v3.reservations.getRewardPointsStatus(fleetTypeSlug, dealId);
                    } catch (e) {
                        return [];
                    }
                },
            })
            .then((data) => {
                this.config = data;
            });
    }
}

export const rewardPointsStore = new RewardPointsStore();
export default rewardPointsStore;
