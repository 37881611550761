import AccountKeyStore from '#/store/AccountKeyStore';
import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useFeatureIsOn } from '#/hooks/useFeatureIsOn';

export const useIsVelocityEnabled = () => {
    const [isAgent, setIsAgent] = useState(false);
    const velocityEnable = useFeatureIsOn('velocity');

    useEffect(
        () =>
            reaction(
                () => AccountKeyStore.isAgent,
                (isAgent) => setIsAgent(isAgent)
            ),
        []
    );

    if (isAgent) {
        return false;
    }

    return velocityEnable;
};
