import { useBonusPointsConfig } from '#/hooks/useBonusPointsConfig';
import { CurrencyValue } from '@jucy/rentals-api-client/rentals-api-v3/models/CurrencyValue';

export const useVelocityPointsEarnEstimate = (value?: CurrencyValue) => {
    const bonusPointsConfig = useBonusPointsConfig();
    const velocityConfig = bonusPointsConfig?.find((c) => c.type === 'velocity');
    const currencyRate = velocityConfig?.eligible && value?.currencyCode ? velocityConfig.exchangeRate?.[value.currencyCode] : null;
    if (!value?.value || !velocityConfig?.eligible || !currencyRate) {
        return {
            basePoints: 0,
            bonusPoints: 0,
        };
    }
    const total = currencyRate * value.value;
    const baseMultiplier = velocityConfig?.baseMultiplier || 3;
    const bonusPointsMultiplier = velocityConfig?.bonusMultiplier || 0;
    const bonusPoints = velocityConfig?.bonusPoints || 0;

    const basePointsComputed = Math.round(total * baseMultiplier);
    const bonusMultiplierPointsComputed = bonusPointsMultiplier ? Math.round(total * bonusPointsMultiplier) - basePointsComputed : 0;
    const bonusTotalComputed = bonusMultiplierPointsComputed + bonusPoints;

    const basePoints = basePointsComputed > velocityConfig.maxBasePoints ? velocityConfig.maxBasePoints : basePointsComputed;
    const bonusMultiplierPoints = bonusTotalComputed > velocityConfig.maxBonusPoints ? velocityConfig.maxBonusPoints : bonusMultiplierPointsComputed;
    const bonusFixedPoints = bonusTotalComputed > velocityConfig.maxBonusPoints ? 0 : bonusPoints;
    const bonusTotal = bonusMultiplierPoints + bonusFixedPoints;

    return {
        basePoints,
        bonusMultiplierPoints,
        bonusFixedPoints,
        bonusPoints: bonusTotal,
        totalPoints: basePoints + bonusTotal,
        hasBonus: bonusTotal > 0,
    };
};
