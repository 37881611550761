import { JucyMuiTextLink } from '#/components/Button/Button';
import ModalDialog from '#/components/ModalDialog';
import { PageTitle } from '#/components/Typograpgy/Title';
import { VelocityLogo } from '#/components/Velocity/VelocityLogo';
import { formatRewardPoints } from '#/lib/formatRewardPoints';
import { isFleetCategoryEarnEligible } from '#/lib/velocity/isFleetCategoryEarnEligible';
import { useIsVelocityEnabled } from '#/lib/velocity/useIsVelocityEnabled';
import { useVelocityPointsEarnEstimate } from '#/lib/velocity/useVelocityPointsEarnEstimate';
import { useBrand } from '#/store/BrandStore';
import TranslationStore from '#/store/TranslationStore';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Box, DialogContent, DialogTitle, Grid, inputBaseClasses, Stack, styled, Typography, IconButton } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ClearIcon from '@mui/icons-material/Clear';
import { CurrencyValue } from '@jucy/rentals-api-client/rentals-api-v3/models/CurrencyValue';

const invalidPaymentTypes: Record<string, string> = {
    afterpay: 'Velocity Points are not available when using Afterpay.',
};

const VelocityMembershipIdClearIndicator = styled(IconButton, {
    name: 'MuiAutocomplete',
    slot: 'ClearIndicator',
    overridesResolver: (props, styles) => styles.clearIndicator,
})({
    padding: 2,
    marginRight: -8,
    visibility: 'hidden',
});

export const VelocityMembershipIdInputField = ({
    fleetCategory,
    disabled,
    purchaseValue,
    countryCode,
    paymentType,
}: {
    fleetCategory: FleetCategory;
    disabled?: boolean;
    purchaseValue?: CurrencyValue;
    countryCode: string | JucyCountryCode;
    paymentType?: string;
}) => {
    const brandStore = useBrand();
    const velocityEnabled = useIsVelocityEnabled();
    const fleetCategoryEligible = isFleetCategoryEarnEligible(fleetCategory);
    const { basePoints, bonusMultiplierPoints, bonusFixedPoints, bonusPoints, totalPoints, hasBonus } = useVelocityPointsEarnEstimate(purchaseValue);
    const [isOpen, setOpen] = useState(false);
    const toggleModel = useCallback(
        (event: React.SyntheticEvent) => {
            event?.preventDefault();
            setOpen(!isOpen);
        },
        [isOpen]
    );
    const { values, setFieldValue } = useFormikContext<{ velocityMembershipId?: string }>();
    const showClearIndicator = Boolean(values?.velocityMembershipId);
    const handleClearIndicatorClick = useCallback(() => setFieldValue('velocityMembershipId', '', true), [setFieldValue]);
    const isInvalidPaymentType = Boolean(paymentType && invalidPaymentTypes[paymentType]);
    const invalidPaymentTypeMessage = paymentType && invalidPaymentTypes[paymentType] ? invalidPaymentTypes[paymentType] : '';
    useEffect(() => {
        if (isInvalidPaymentType) {
            setFieldValue('velocityMembershipId', '', true);
        }
    }, [isInvalidPaymentType, setFieldValue]);
    const { t } = useTranslation(TranslationStore.currentNamespace);
    if (!velocityEnabled || !fleetCategoryEligible || !totalPoints) {
        return null;
    }
    const translationVars = {
        basePoints: formatRewardPoints(basePoints),
        bonusMultiplier: formatRewardPoints(bonusMultiplierPoints),
        bonusFixedPoints: formatRewardPoints(bonusFixedPoints),
        bonusPoints: formatRewardPoints(bonusPoints),
        totalPoints: formatRewardPoints(totalPoints),
    };

    return (
        <>
            <Grid item xs={12} md={6} sx={{ mt: { sm: 0, md: 2 } }}>
                <Stack
                    direction="row"
                    gap={2}
                    sx={{
                        justifyContent: { sm: 'center', md: 'space-between' },
                    }}
                >
                    <VelocityLogo alt="Enter your Velocity number to earn points" />
                    <Stack>
                        <Typography variant="body2" sx={{ textAlign: 'center' }}>
                            {t(`hirer_details.vff-earn-estimate${hasBonus ? '-bonus' : ''}`, translationVars)}
                            <sup>†</sup>
                            <br />
                        </Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <button type="button" className="btn btn-link btn-tertiary px-0 mt-auto" onClick={() => setOpen(true)}>
                                <i className="fas fa-info-circle" /> More details
                            </button>
                        </Box>
                    </Stack>
                </Stack>
                <ModalDialog open={isOpen} onClose={toggleModel}>
                    <DialogTitle component="div">
                        <PageTitle>Velocity Frequent Flyer Points</PageTitle>
                    </DialogTitle>
                    <DialogContent>
                        {t('hirer_details.vff-earn-details', translationVars)}
                        <br />
                        <br />
                        <ReactMarkdown
                            components={{
                                a: (props) => {
                                    const href = props.href === '%7B%7BtermsLink%7D%7D' || props.href === '{{termsLink}}' ? brandStore.termsPageUrl({ countryCode }) : props.href;
                                    return (
                                        <JucyMuiTextLink href={href} sx={{ padding: 0 }} target="_blank" rel="noopener noreferrer" external={true}>
                                            {props.children}
                                        </JucyMuiTextLink>
                                    );
                                },
                            }}
                        >
                            {t('hirer_details.vff-earn-terms', {
                                ...translationVars,
                                termsLink: brandStore.termsPageUrl({ countryCode }),
                            })}
                        </ReactMarkdown>
                    </DialogContent>
                </ModalDialog>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { sm: 0, md: 2 } }}>
                <Field
                    component={TextField}
                    fullWidth
                    size="small"
                    label="Velocity membership number"
                    type="text"
                    name="velocityMembershipId"
                    sx={{
                        [`& .${inputBaseClasses.focused},:hover`]: {
                            '& .clearIndicator': {
                                visibility: 'visible',
                            },
                        },
                    }}
                    helperText={isInvalidPaymentType ? invalidPaymentTypeMessage : ''}
                    InputProps={{
                        endAdornment: showClearIndicator ? (
                            <VelocityMembershipIdClearIndicator className="clearIndicator" onClick={handleClearIndicatorClick}>
                                <ClearIcon fontSize="small" />
                            </VelocityMembershipIdClearIndicator>
                        ) : undefined,
                    }}
                    inputProps={{
                        'data-cy': 'velocity',
                        autoComplete: 'one-time-code',
                    }}
                    disabled={disabled || isInvalidPaymentType}
                />
            </Grid>
        </>
    );
};
