import { useEffect, useState } from 'react';
import rewardPointsStore from '#/store/RewardPointsStore';
import { reaction } from 'mobx';

export const useBonusPointsConfig = () => {
    const [config, setConfig] = useState(rewardPointsStore.config);

    useEffect(
        () =>
            reaction(
                () => rewardPointsStore.config,
                (config) => {
                    setConfig(config);
                }
            ),
        []
    );
    return config;
};
