import { isFleetCategoryEarnEligible } from '#/lib/velocity/isFleetCategoryEarnEligible';
import { useIsVelocityEnabled } from '#/lib/velocity/useIsVelocityEnabled';
import TranslationStore from '#/store/TranslationStore';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';

export const VelocityHirerDetailsFormFooter = ({ fleetCategory }: { fleetCategory: FleetCategory }) => {
    const velocityEnabled = useIsVelocityEnabled();
    const fleetCategoriesEligible = isFleetCategoryEarnEligible(fleetCategory);
    const { t } = useTranslation(TranslationStore.currentNamespace);
    if (!velocityEnabled || !fleetCategoriesEligible) {
        return null;
    }
    return (
        <Typography variant="caption" color="text.secondary" component="small" sx={{ display: 'block' }}>
            <sup>†</sup> {t('fleet_result_list.vff-earn-footer')}
        </Typography>
    );
};
